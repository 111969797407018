import React from 'react';
import { CMSDimensionType } from '../../constants/common';
import Template16_9 from './Template16_9';
import Template1_1 from './Template1_1';
import Template9_16 from './Template9_16';
import { TemplateColumnProps } from './types';

const TemplateColumn = (props: TemplateColumnProps) => {
  const { column, isVideoWall, isMiniVideoWall } = props;
  return (
    <React.Fragment>
      {column.map((card, idx) => {
        switch (card.dimension) {
          case CMSDimensionType._9_16:
            return (
              <Template9_16
                card={card}
                key={idx}
                isVideoWall={isVideoWall}
                isMiniVideoWall={isMiniVideoWall}
              />
            );
          case CMSDimensionType._16_9:
            return (
              <Template16_9
                card={card}
                key={idx}
                isVideoWall={isVideoWall}
                isMiniVideoWall={isMiniVideoWall}
              />
            );
          default:
            return (
              <Template1_1
                card={card}
                key={idx}
                isVideoWall={isVideoWall}
                isMiniVideoWall={isMiniVideoWall}
              />
            );
        }
      })}
    </React.Fragment>
  );
};

export default TemplateColumn;
