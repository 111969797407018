import { PlatformType } from '@invideoio/web-shared/constants/common';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';
import SingleSlide from './SingleSlide';
import { TemplateSlideProps } from './types';

const TemplateSlide = (props: TemplateSlideProps) => {
  const { platform, slide, isVideoWall, isMiniVideoWall } = props;
  const { column_1, column_2, column_3, column_4 } = slide;
  return (
    (platform === PlatformType.Desktop && (
      <div
        className={ivclass(
          'iv-flex',
          'xl:iv-gap-40 lg:iv-gap-30 md:iv-gap-20 sm:iv-gap-[16px]',
          'xl:iv-pr-20 xl:iv-pl-20 lg:iv-pr-15 lg:iv-pl-15 md:iv-pl-10 md:iv-pr-10 sm:iv-pr-8 sm:iv-pl-8',
        )}
      >
        <SingleSlide
          column_1={column_1}
          column_2={column_2}
          column_3={column_3}
          column_4={column_4}
          isVideoWall={isVideoWall}
          isMiniVideoWall={isMiniVideoWall}
        />
      </div>
    )) || (
      <SingleSlide
        column_1={column_1}
        column_2={column_2}
        column_3={column_3}
        column_4={column_4}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
    )
  );
};

export default TemplateSlide;
