import React, { MouseEventHandler } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface RightButtonProps {
  clickHandler: MouseEventHandler<HTMLButtonElement>;
  isEndReached: boolean;
}

const NextButton = (props: RightButtonProps) => {
  const { clickHandler, isEndReached } = props;

  return (
    <button
      className={ivclass(
        'iv-absolute',
        'iv-backdrop-blur-[30px]',
        'iv-rounded-30',
        'iv-top-1/2',
        'iv--translate-y-2/4',
        'iv-right-[40px]',
        'iv-px-25 iv-py-15',
        'iv-bg-black-70 hover:iv-bg-grey-100 iv-transition',
        isEndReached && 'iv-hidden',
      )}
      onClick={clickHandler}
    >
      <span className="iv-flex">
        <ImageComponent
          elementype="native"
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/homepage/next-arrow.svg`}
          width={28}
          height={17}
          alt="next-button"
          loading="lazy"
        />
      </span>
    </button>
  );
};

export default NextButton;
