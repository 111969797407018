import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React, { useState } from 'react';
import { TemplateCardProps } from './types';
import VideoTemplate from './VideoTemplate';
import VideoWallTemplate from './VideoWallTemplate';

const Template1_1 = (props: TemplateCardProps) => {
  const { card, isVideoWall, isMiniVideoWall } = props;
  const { thumbnailURL, previewURL, templateId } = card;

  const [play, setPlay] = useState(false);

  const playVideo = () => {
    setPlay(true);
  };

  const pauseVideo = () => {
    setPlay(false);
  };

  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-template-hover',
        'xl:iv-w-[245px] lg:iv-w-[230px] md:iv-w-[166px] sm:iv-w-[102px]',
        'xl:iv-h-[245px] lg:iv-h-[230px] md:iv-h-[166px] sm:iv-h-[102px]',
        'iv-rounded-[15px] iv-overflow-hidden',
        'iv-bg-black-4',
      )}
      onMouseOver={playVideo}
      onMouseLeave={pauseVideo}
    >
      {isVideoWall || isMiniVideoWall ? (
        <VideoWallTemplate
          id={templateId}
          thumbnailURL={thumbnailURL}
          previewURL={previewURL}
          play={play}
          width={1}
          height={1}
          isVideoWall={isVideoWall}
          isMiniVideoWall={isMiniVideoWall}
        />
      ) : (
        <VideoTemplate
          id={templateId}
          thumbnailURL={thumbnailURL}
          previewURL={previewURL}
          play={play}
          width={1}
          height={1}
        />
      )}
    </div>
  );
};

export default Template1_1;
