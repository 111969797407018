import React from 'react';
import { SingleSlideProps } from '../../types';
import SingleColumn from './SingleColumn';

const SingleSlide = (props: SingleSlideProps) => {
  const {
    column_1,
    column_2,
    column_3,
    column_4,
    isVideoWall,
    isMiniVideoWall,
  } = props;
  return (
    <React.Fragment>
      <SingleColumn
        column={column_1}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
      <SingleColumn
        column={column_2}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
      <SingleColumn
        column={column_3}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
      <SingleColumn
        column={column_4}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
    </React.Fragment>
  );
};

export default SingleSlide;
