import React, { MouseEventHandler } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface LeftButtonProps {
  clickHandler: MouseEventHandler<HTMLButtonElement>;
  isEndReached: boolean;
}

const PrevButton = (props: LeftButtonProps) => {
  const { clickHandler, isEndReached } = props;

  return (
    <button
      className={ivclass(
        'iv-absolute',
        'iv-backdrop-blur-[30px]',
        'iv-rounded-30',
        'iv-top-1/2',
        'iv--translate-y-2/4',
        'iv-left-[40px]',
        'iv-px-25 iv-py-15',
        'iv-bg-black-70 hover:iv-bg-grey-100 iv-transition',
        isEndReached && 'iv-hidden',
      )}
      onClick={clickHandler}
    >
      <span className="iv-flex">
        <ImageComponent
          elementype="native"
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/homepage/previous-arrow.svg`}
          width={25}
          height={14}
          alt="prev-button"
          loading="lazy"
        />
      </span>
    </button>
  );
};

export default PrevButton;
