const assetsHost = `${process.env.NEXT_PUBLIC_CDN_URL}/make-page`;
const baseHost = process.env.NEXT_PUBLIC_STUDIO_HOST;
const SHARED_CDN_URL = `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}/icons`;

export const CTATemplateUrl = `https://invideo.io/workflow/marketing-templates`;
export const CTAInvideoAiPromptPage = `https://ai.invideo.io/`;
export const CTAVideoWallTemplateUrl = `/ai-videos/`;

export const Faq = {
  title: {
    highlightedHeading: 'know more?',
    plainHeading: 'Want to ',
  },
  items: [
    {
      heading: 'How do I trim a video clip online?',
      description: [
        {
          answer: `Quick tools video trimmer lets you remove the ends of your video. To trim a video all you need to do is upload your video file and move the blue handles of the clip to the desired position on the timeline.`,
        },
        {
          answer: `For precise control, you can manually enter in and out times of the clip to the decimal precision. In time represents the time stamp where you would like your clip to start. Similarly, out time represents the time stamp where you would like your clip to end. In and out times manipulation would have the same result as moving the handles of the clip.`,
        },
      ],
    },
    {
      heading:
        'What is the difference between cut video vs. trim vs. crop video?',
      description: [
        {
          answer: `‘Trim’ video and ‘Cut’ video refer to editing the duration of a video clip, ie. making it shorter. ‘Crop’ a video refers to removing part of the video’s image`,
        },
      ],
    },
    {
      heading: 'What video file types can I export from Quick tools?',
      description: [
        {
          answer: `Quick tools support exporting videos in various file types such as: MP4, MOV, WebM, AVI, QuickTime, and others. With a few clicks, export your video optimized for social media.`,
        },
      ],
    },
    {
      heading: 'How long should my video be?',
      description: [
        {
          answer: `Quick tools are intended for shorter videos, mainly for social media. For example Instagram Reel videos can only be 15 seconds long and TikTok videos can be up to 1 minute. Quick tools are perfect for these kind of edits. If you would like to do more professional editing, with plenty of pro features, you can use our InVideo full featured editor.`,
        },
      ],
    },
    {
      heading: 'How to trim a video on iPhone or Android?',
      description: [
        {
          answer: `Quick tools are supported for your laptop or desktop computer, but if you would like to trim a video on your mobile device, you can download Filmr - our mobile video editing app.`,
        },
      ],
    },
  ],
  icons: {
    collapse: `${SHARED_CDN_URL}/rounded-close.svg`,
    expand: `${SHARED_CDN_URL}/rounded-open.svg`,
  },
  iconsDark: {
    collapse: `${SHARED_CDN_URL}/rounded-close-dark.svg`,
    expand: `${SHARED_CDN_URL}/rounded-open-dark.svg`,
  },
};

export const Creation = {
  heading: {
    plainHeading: 'How to ',
    highlightedHeading: 'make a video',
  },
  CTAUrl: `${baseHost}/workflow/marketing-templates`,
  buttonText: 'Get started - it’s Free!',
  card1: {
    heading: { heading1: 'Select a video template of your choice' },
    detail: {
      highlightedText: 'Use our library of 5000+ templates. ',
      plainText2:
        'You can easily search templates across different categories.',
    },
    image: {
      url: `${assetsHost}/select-video-template.png`,
      alternate: '',
    },
  },
  card2: {
    heading: { heading1: 'Upload your media' },
    detail: {
      highlightedText: 'Upload your own images, videos and music, ',
      plainText1: 'add them seamlessly anywhere you like to.',
    },
    image: {
      url: `${assetsHost}/upload-media.png`,
      alternate: '',
    },
  },
  card3: {
    heading: { heading1: 'Customize your message' },
    detail: {
      highlightedText: 'Match your brand by ',
      plainText1: 'rewriting copy, ',
      plainText2: 'matching fonts and rearranging visuals.',
    },
    image: {
      url: `${assetsHost}/customize-message.png`,
      alternate: '',
    },
  },
};

const GUIDE_CARDS = [
  {
    heading: { heading1: 'Start a new project' },
    detail: {
      highlightedText: 'Choose a video type to create, ',
      plainText1:
        'from TikTok Video, Video Slideshow, Video Collage, YouTube Video, Instagram Stories, etc. Or, start from scratch.',
    },
  },
  {
    heading: { heading1: 'Get creative with more design ingredients' },
    detail: {
      highlightedText:
        'Explore millions of icons, illustrations, images and fonts. ',
      plainText1:
        'With InVideo’s advanced editing tools, you can add borders, frames, logos, text, icons and other ingredients anywhere on your business card.',
    },
  },
  {
    heading: { heading1: 'Start a new project' },
    detail: {
      highlightedText: 'Choose a video type to create, ',
      plainText1:
        'from TikTok Video, Video Slideshow, Video Collage, YouTube Video, Instagram Stories, etc. Or, start from scratch.',
    },
  },
  {
    heading: { heading1: 'Start a new project' },
    detail: {
      highlightedText: 'Choose a video type to create, ',
      plainText1:
        'from TikTok Video, Video Slideshow, Video Collage, YouTube Video, Instagram Stories, etc. Or, start from scratch.',
    },
  },
  {
    heading: { heading1: 'Start a new project' },
    detail: {
      highlightedText: 'Choose a video type to create, ',
      plainText1:
        'from TikTok Video, Video Slideshow, Video Collage, YouTube Video, Instagram Stories, etc. Or, start from scratch.',
    },
  },
];

export const GUIDE_SECTION = {
  cards: GUIDE_CARDS,
  heading: {
    plainHeading: 'How to edit ',
    highlightedHeading: 'your videos',
  },
};

export const CreationSectionNonTemplate = {
  heading: {
    plainHeading: 'How to ',
    highlightedHeading: 'make a video',
  },
  CTAUrl: `${baseHost}/workflow/marketing-templates`,
  buttonText: 'Get started - it’s Free!',
  card1: {
    heading: { heading1: 'Select a video template of your choice' },
    detail: {
      highlightedText: 'Use our library of 5000+ templates. ',
      plainText2:
        'You can easily search templates across different categories.',
    },
    image: {
      url: `${assetsHost}/upload-media.png`,
      alternate: '',
    },
  },
  card2: {
    heading: { heading1: 'Upload your media' },
    detail: {
      highlightedText: 'Upload your own images, videos and music, ',
      plainText1: 'add them seamlessly anywhere you like to.',
    },
    image: {
      url: `${assetsHost}/customize-your-message-9-16.png`,
      alternate: '',
    },
  },
  card3: {
    heading: { heading1: 'Customize your message' },
    detail: {
      highlightedText: 'Match your brand by ',
      plainText1: 'rewriting copy, ',
      plainText2: 'matching fonts and rearranging visuals.',
    },
    image: {
      url: `${assetsHost}/polish-video.png`,
      alternate: '',
    },
  },
};

export const TemplateSection = {
  heading: {
    plainHeading: 'Choose your ',
    highlightedHeading: 'template',
  },
  buttonText: 'See all templates',
  CTAUrl: `${baseHost}/workflow/marketing-templates`,
  slide_1: {
    column_1: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb.png`,
      },
    ],
    column_2: [
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (1).png`,
      },
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (2).png`,
      },
    ],
    column_3: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb (3).png`,
      },
    ],
    column_4: [
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (4).png`,
      },
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (5).png`,
      },
    ],
  },
  slide_2: {
    column_1: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb.png`,
      },
    ],
    column_2: [
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (1).png`,
      },
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (2).png`,
      },
    ],
    column_3: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb (3).png`,
      },
    ],
    column_4: [
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (4).png`,
      },
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (5).png`,
      },
    ],
  },
  slide_3: {
    column_1: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb.png`,
      },
    ],
    column_2: [
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (1).png`,
      },
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (2).png`,
      },
    ],
    column_3: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb (3).png`,
      },
    ],
    column_4: [
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (4).png`,
      },
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (5).png`,
      },
    ],
  },
  slide_4: {
    column_1: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb.png`,
      },
    ],
    column_2: [
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (1).png`,
      },
      {
        id: '123',
        dimension: '16:9',
        url: `${assetsHost}/Thumb (2).png`,
      },
    ],
    column_3: [
      {
        id: '123',
        dimension: '9:16',
        url: `${assetsHost}/Thumb (3).png`,
      },
    ],
    column_4: [
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (4).png`,
      },
      {
        id: '123',
        dimension: '1:1',
        url: `${assetsHost}/Thumb (5).png`,
      },
    ],
  },
};

export const TrustedBySection = {
  trustedBy: [
    {
      image: {
        url: `${assetsHost}/Kw.png`,
      },
    },
    {
      image: {
        url: `${assetsHost}/Remax.png`,
      },
    },
    {
      image: {
        url: `${assetsHost}/Century21.png`,
      },
    },
    {
      image: {
        url: `${assetsHost}/Realtor.png`,
      },
    },
  ],
  sectionHead: 'Trusted By',
};

export const SuperbVideosSection = {
  heading: {
    highlightedHeading: 'Superb Videos',
    plainHeading: `Let's create`,
  },
  detail: {
    highlightedText: 'Start your video with a bang.',
    plainText1: 'You can now easily create professional-looking ',
    plainText2: 'videos that will hook your audience immediately.',
  },
  buttonText: `Get Started-it's Free!`,
  CTAUrl: '',
};
