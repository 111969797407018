import React, { useContext } from 'react';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import { useEffect, useRef } from 'react';
import {
  CTATemplateUrl,
  CTAVideoWallTemplateUrl,
  CTAInvideoAiPromptPage,
} from '../../constants/makepage';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from '../../types/analytics.types';
import { StateContext } from '@store/store';
import { BaseAppType } from '@constants/common';
import VideoComponentWithVolumeControls from '@components/common/VideoComponent/VideoComponentWithVolumeControls';

interface VideoTemplateProps {
  id: number;
  thumbnailURL: string;
  previewURL: string;
  play: boolean;
  width: number;
  height: number;
  isVideoWall?: boolean;
  isMiniVideoWall?: boolean;
}

const VideoWallTemplate = (props: VideoTemplateProps) => {
  const { baseAppType } = useContext(StateContext);
  const {
    thumbnailURL,
    previewURL,
    play,
    width,
    height,
    id,
    isVideoWall,
    isMiniVideoWall,
  } = props;

  const videoElement = useRef<HTMLVideoElement>(null);
  const videoElementPromise = useRef<Promise<void> | null>(null);

  useEffect(() => {
    if (play) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [play]);

  const playVideo = () => {
    const video = videoElement?.current;
    if (video) {
      videoElementPromise.current = video.play();
    }
  };

  const pauseVideo = () => {
    const video = videoElement?.current;
    if (video && videoElementPromise.current) {
      videoElementPromise.current.then(() => {
        video.pause();
      });
      video.currentTime = 0;
    }
  };

  const openTemplate = () => {
    let clickEvent = AmplitudeEvents.openTemplate;
    if (isVideoWall && !isMiniVideoWall) {
      clickEvent = AmplitudeEvents.openVideoWallTemplate;
    } else if (isMiniVideoWall) {
      clickEvent = AmplitudeEvents.clickSignUp;
    }
    sendAmplitudeAnalyticEvent(clickEvent, {
      'Template id': id,
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source:
          isVideoWall || isMiniVideoWall
            ? ClickCtaUxSource.VideoWallTemplateGrid
            : ClickCtaUxSource.TemplateGrid,
        feature_utm: `template_id: ${id}`,
        type: isMiniVideoWall
          ? ClickCtaType.ClickSignup
          : ClickCtaType.ClickOpenTemplate,
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    setTimeout(() => {
      let url = `${CTATemplateUrl}/${id}`;
      if (isVideoWall && !isMiniVideoWall) {
        url = `${CTAVideoWallTemplateUrl}?template_id=${id}&show_preview=true`;
      } else if (isMiniVideoWall) {
        url = `${CTAInvideoAiPromptPage}`;
      }
      window.open(url, '_self');
    }, 20);
  };

  return (
    (thumbnailURL && (
      <div className={ivclass('iv-w-full iv-h-full', 'iv-cursor-pointer')}>
        <VideoComponentWithVolumeControls
          playsInline
          preload="none"
          loop
          {...(isVideoWall && { muted: true })}
          data-poster={thumbnailURL}
          className={ivclass(
            'iv-object-cover',
            'iv-h-full iv-w-full',
            'lazyload',
          )}
          ref={videoElement}
          onClick={openTemplate}
          isVideoWall={isVideoWall}
        >
          <source data-src={previewURL} type="video/mp4" />
        </VideoComponentWithVolumeControls>
        <div className="iv-template-poster-image iv-absolute iv-top-0 iv-bottom-0 iv-h-full iv-w-full">
          <ImageComponent
            src={thumbnailURL}
            style={{
              width: '100%',
              height: 'auto',
            }}
            sizes="100vw"
            alt="logo"
            width={width}
            height={height}
            loading="lazy"
            elementype="next"
            onClick={openTemplate}
          />
        </div>
      </div>
    )) || <React.Fragment></React.Fragment>
  );
};

export default VideoWallTemplate;
