import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React, { useState } from 'react';
import { TemplateCardProps } from './types';
import VideoTemplate from './VideoTemplate';
import VideoWallTemplate from './VideoWallTemplate';

const Template9_16 = (props: TemplateCardProps) => {
  const { card, isVideoWall, isMiniVideoWall } = props;
  const { thumbnailURL, previewURL, templateId } = card;

  const [play, setPlay] = useState(false);

  const playVideo = () => {
    setPlay(true);
  };

  const pauseVideo = () => {
    setPlay(false);
  };

  return (
    <div
      className={ivclass('iv-flex')}
      onMouseOver={playVideo}
      onMouseLeave={pauseVideo}
    >
      <div
        className={ivclass(
          'iv-relative',
          'iv-template-hover',
          'xl:iv-w-[300px] lg:iv-w-[280px] md:iv-w-[200px] sm:iv-w-[125px]',
          'xl:iv-h-[529px] lg:iv-h-[490px] md:iv-h-[352px] sm:iv-h-[220px]',
          'iv-rounded-[15px] iv-overflow-hidden',
          'iv-bg-black-4',
        )}
      >
        {isVideoWall || isMiniVideoWall ? (
          <VideoWallTemplate
            id={templateId}
            thumbnailURL={thumbnailURL}
            previewURL={previewURL}
            play={play}
            width={9}
            height={16}
            isVideoWall={isVideoWall}
            isMiniVideoWall={isMiniVideoWall}
          />
        ) : (
          <VideoTemplate
            id={templateId}
            thumbnailURL={thumbnailURL}
            previewURL={previewURL}
            play={play}
            width={9}
            height={16}
          />
        )}
      </div>
    </div>
  );
};

export default Template9_16;
