import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import useEmblaCarousel, { UseEmblaCarouselType } from 'embla-carousel-react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

export interface SliderProps {
  infinite?: boolean;
  autoPlay?: boolean;
  pauseOnHover?: boolean;
  autoPlaySpeed?: number;
  renderButtons?: (
    scrollPrev: MouseEventHandler<HTMLButtonElement>,
    scrollNext: MouseEventHandler<HTMLButtonElement>,
    shouldShowNext: boolean,
    shouldShowPrev: boolean,
  ) => JSX.Element;
  sliderWrapperClasses?: string;
  scrollSpeed?: number;
  children: ReactNode;
  align?: 'start' | 'end' | 'center' | number;
  childrenWrapperClass?: string;
}

const Slider = (props: SliderProps) => {
  const {
    renderButtons,
    infinite = false,
    autoPlay,
    autoPlaySpeed = 1500,
    pauseOnHover,
    sliderWrapperClasses,
    scrollSpeed = 20,
    children,
    align,
    childrenWrapperClass,
  } = props;

  const sliderRef = useRef<null | HTMLDivElement>(null);

  const [viewportRef, embla]: UseEmblaCarouselType = useEmblaCarousel({
    skipSnaps: false,
    speed: scrollSpeed,
    align: align || 'start',
    containScroll: 'trimSnaps',
    draggable: true,
    dragFree: true,
    ...{ loop: infinite },
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(infinite);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(infinite);
  const [disableSlider, setDisableSlider] = useState(false);

  const sliderBtnRef = useRef({
    nextBtnEnabled: false,
    prevBtnEnabled: false,
  });
  const sliderAutoPlayRef = useRef<null | any>(null);

  const scrollPrev = () => embla && embla.scrollPrev();

  const scrollNext = () => embla && embla.scrollNext();

  const onSelect = () => {
    if (!embla) {
      return;
    }
    const prev = embla.canScrollPrev();
    const next = embla.canScrollNext();

    if (sliderBtnRef.current.prevBtnEnabled !== prev) {
      sliderBtnRef.current.prevBtnEnabled = prev;
      setPrevBtnEnabled(prev);
    }
    if (sliderBtnRef.current.nextBtnEnabled !== next) {
      sliderBtnRef.current.nextBtnEnabled = next;
      setNextBtnEnabled(next);
    }
  };

  const autoPlayToNextSlide = () => {
    if (!embla || !document.hasFocus()) {
      return;
    }
    if (embla?.canScrollNext()) {
      embla?.scrollNext();
    } else {
      embla?.scrollTo(0);
    }
    sliderAutoPlayRef.current = setTimeout(autoPlayToNextSlide, autoPlaySpeed);
  };

  useEffect(() => {
    if (!embla) {
      return;
    }

    if (renderButtons) {
      embla.on('select', onSelect);
      onSelect();
    }

    if (autoPlay) {
      setTimeout(autoPlayToNextSlide, autoPlaySpeed);
    }

    if (
      !sliderBtnRef.current.prevBtnEnabled &&
      !sliderBtnRef.current.nextBtnEnabled
    ) {
      setDisableSlider(true);
    }
  }, [embla]);

  const toggleAutoplay = (play: boolean) => {
    if (!embla) {
      return;
    }
    if (pauseOnHover) {
      if (play) {
        clearTimeout(sliderAutoPlayRef.current);
      } else {
        sliderAutoPlayRef.current = setTimeout(
          autoPlayToNextSlide,
          autoPlaySpeed,
        );
      }
    }
  };

  return (
    <div
      onMouseOver={() => toggleAutoplay(true)}
      onMouseLeave={() => toggleAutoplay(false)}
      onTouchStart={() => toggleAutoplay(true)}
      onTouchEnd={() => toggleAutoplay(false)}
      className={ivclass('iv-relative', sliderWrapperClasses)}
    >
      <div ref={viewportRef}>
        <div
          className={ivclass(
            'iv-flex',
            disableSlider && 'iv-justify-center',
            childrenWrapperClass,
          )}
          ref={sliderRef}
        >
          {children}
        </div>
      </div>

      {renderButtons &&
        renderButtons(scrollPrev, scrollNext, !prevBtnEnabled, !nextBtnEnabled)}
    </div>
  );
};
export default Slider;
