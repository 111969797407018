import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';
import TemplateColumn from './TemplateColumn';
import { SingleColumnProps } from './types';

const SingleColumn = (props: SingleColumnProps) => {
  const { column, isVideoWall, isMiniVideoWall } = props;

  return (
    <div
      className={ivclass(
        'iv-flex',
        'iv-flex-col iv-justify-between',
        'xl:iv-gap-40 lg:iv-gap-30 md:iv-gap-20 sm:iv-gap-[16px]',
        'xl:iv-h-[529px] lg:iv-h-[490px] md:iv-h-[352px] sm:iv-h-[220px]',
      )}
    >
      <TemplateColumn
        column={column}
        isVideoWall={isVideoWall}
        isMiniVideoWall={isMiniVideoWall}
      />
    </div>
  );
};

export default SingleColumn;
